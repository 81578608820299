import organisationImage from 'assets/images/organisation.png';
import { useState } from 'react';
import { YEAR_GROUPS } from 'utils/values';

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { StyledYearGroupChip } from '../styles';

const GetMarketingInfoPopup = () => {
  const [open, setOpen] = useState(false);
  const [selectedYearGroups, setSelectedYearGroups] = useState([]);
  const theme = useTheme();
  const isScreenLtSm = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelect = (event) => {
    const currentLabel = event.target.innerText;
    setSelectedYearGroups((yearGroups) =>
      yearGroups.indexOf(currentLabel) !== -1
        ? yearGroups.filter((groupLabel) => groupLabel !== currentLabel)
        : [...yearGroups, currentLabel],
    );
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      fullScreen={isScreenLtSm}
      PaperProps={{
        component: 'form',
        elevation: 4,
        onSubmit: (event) => {
          event.preventDefault();
          const formData = new FormData(event.currentTarget);
          const formJson = Object.fromEntries(formData.entries());
          const { email } = formJson;
          console.log(email);
          handleClose();
        },
      }}
    >
      <Box sx={{ maxWidth: 480, alignSelf: 'center', py: 4 }}>
        <DialogTitle sx={{ textAlign: 'center' }} gutterBottom>
          <Box component="img" src={organisationImage} alt="Contact info about teachers" />
          <Typography component="p" variant="h6">
            Please share your details to stay updated on available support, CLPL webinars and programme updates
          </Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            name="email"
            label="Preferred Email *"
            type="email"
            size="small"
            fullWidth
          />
          <DialogContentText sx={{ fontSize: 12, px: 2, mb: 3 }}>
            This is where we will send programme updates and support. It does not need to match your school/login email.
          </DialogContentText>
          <Typography component="h2" gutterBottom>
            Which year groups do you teach Gen+? *
          </Typography>
          {YEAR_GROUPS.map((label) => {
            const checked = selectedYearGroups.includes(label);
            return (
              <StyledYearGroupChip
                key={label}
                clickable
                data-value={label}
                variant={checked ? 'primary' : 'outlined'}
                sx={{
                  backgroundColor: checked ? 'primary.main' : 'outlined',
                  color: checked ? 'primary.contrastText' : '',
                }}
                label={label}
                onClick={handleSelect}
              />
            );
          })}
          <DialogContentText sx={{ fontSize: 12, px: 2, mb: 3, mt: 1 }}>
            This is to help us better understand how the platform is used.
          </DialogContentText>
          <Box sx={{ px: 2 }}>
            <FormControlLabel control={<Checkbox />} label="Accept Copyright Terms *" />
            <DialogContentText sx={{ fontSize: 12, mb: 3 }}>
              Gen+ Copyright Notice: Unauthorised reproduction, downloading, sharing, distribution, printing, or passing
              off of this work is strictly prohibited.
            </DialogContentText>
          </Box>
          <Box sx={{ px: 2 }}>
            <FormControlLabel control={<Checkbox />} label="Allow Marketing Communications" />
            <DialogContentText sx={{ fontSize: 12 }}>
              We’d love to be able to keep you up to date with the latest support, programme updates and CLPL webinars.
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button size="large" variant="contained" type="submit">
            Confirm
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default GetMarketingInfoPopup;
