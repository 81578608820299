import { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, TextField } from '@mui/material';
import useEditor from 'hooks/useEditor';
import { notifyError, notifySuccess } from 'utils/notification';


import { useCreateJournalMutation } from '../journalsApi';

const AddJournal = ({ open, handleClose, skills }) => {
  const [title, setTitle] = useState('');
  const [skill, setSkill] = useState('');
  const { clearEditorState, getEditorStateInJson, isEditorEmpty, Editor } = useEditor();
  const [createJournal, { isLoading }] = useCreateJournalMutation();

  const disableSaveButton = !title || !skill || isEditorEmpty() || isLoading;

  const handleCreateJournal = async () => {
    const data = {
      title,
      skill,
      description: getEditorStateInJson(),
    };

    try {
      const res = await createJournal(data).unwrap();

      clearEditorState();
      setTitle('');
      setSkill('');

      notifySuccess(res);
      handleClose();
    } catch (error) {
      notifyError(error.data.detail);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth PaperProps={{ elevation: 3 }}>
      <DialogTitle>New Journal Entry</DialogTitle>

      <DialogContent>
        <Grid container spacing={2} pt={1}>
          <Grid item xs={12} sm={7.5}>
            <TextField
              id="journal-title"
              label="Title"
              size="small"
              variant="outlined"
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </Grid>

          <Grid item xs={12} sm={4.5}>
            <TextField
              select
              id="journal-skill"
              label="Skill"
              size="small"
              fullWidth
              value={skill}
              onChange={(e) => setSkill(e.target.value)}
              required
            >
              {skills?.map((currentSkill) => (
                <MenuItem value={currentSkill.id} key={currentSkill.id}>
                  {currentSkill.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12}>
            {Editor}
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'flex-start' }}>
        <Button type="submit" variant="contained" onClick={handleCreateJournal} disabled={disableSaveButton}>
          Save
        </Button>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddJournal;
