import { Avatar, Chip, styled } from '@mui/material';

export const StyledDashboard = styled('div')`
  position: relative;
`;

export const StyledAvatar = styled(Avatar)`
  position: relative;
  height: 56px;
  width: 56px;
  cursor: pointer;
  transition: box-shadow 0.3s ease;

  &.active {
    box-shadow: 0 0 0 3px ${(props) => props.theme.palette.primary.main};
  }
`;

export const StyledYearGroupChip = styled(Chip)`
  min-width: 50px;
  margin-right: 10px;
`;

export default StyledDashboard;
