import baseApiSlice from 'api/baseApiSlice';
import { GENZ_API_URL, GENZ_BADGES_API_URL, GENZ_LEARNING_API_URL, GENZ_TEACH_API_URL } from 'config';

const dashboardApiWithTags = baseApiSlice.enhanceEndpoints({
  addTagTypes: ['Activity', 'Progress'],
});
const dashboardApiSlice = dashboardApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getProgress: builder.query({
      query: () => ({
        url: `${GENZ_LEARNING_API_URL}/student-dashboard/`,
      }),
      providesTags: ['Progress'],
    }),
    getActiveProgramBadges: builder.query({
      query: () => ({
        url: `${GENZ_BADGES_API_URL}/active/program-badges/`,
      }),
    }),
    getActivityFeed: builder.query({
      query: (page = 1, pageSize = 12) => ({
        url: `${GENZ_LEARNING_API_URL}/activities/?page=${page}&page_size=${pageSize}`,
      }),
      providesTags: (result) =>
        result?.results
          ? [...result.results.map(({ id }) => ({ type: 'Activity', id })), { type: 'Activity', id: 'LIST' }]
          : [{ type: 'Activity', id: 'LIST' }],
    }),
    readFeedActivity: builder.mutation({
      query: (id) => ({
        url: `${GENZ_LEARNING_API_URL}/activities/${id}/read/`,
        method: 'PUT',
      }),
      invalidatesTags: [{ type: 'Activity', id: 'LIST' }],
    }),
    joinClassByCode: builder.mutation({
      query: (joinCode) => ({
        url: `${GENZ_API_URL}/primary/join/`,
        method: 'POST',
        data: { joinCode },
      }),
    }),
    getQuote: builder.query({
      query: () => ({
        url: `${GENZ_TEACH_API_URL}quote/`,
      }),
    }),
    getRecentBadges: builder.query({
      query: () => ({
        url: `${GENZ_BADGES_API_URL}/recent-badges/`,
      }),
    }),
    getFeaturedArticle: builder.query({
      query: () => ({
        url: `${GENZ_TEACH_API_URL}articles/featured/`,
      }),
    }),
    getAlertBarEntry: builder.query({
      query: () => ({
        url: `${GENZ_TEACH_API_URL}alert-bar/`,
      }),
    }),
  }),
});

export const {
  useGetProgressQuery,
  useGetActiveProgramBadgesQuery,
  useGetActivityFeedQuery,
  useReadFeedActivityMutation,
  useJoinClassByCodeMutation,
  useGetQuoteQuery,
  useGetRecentBadgesQuery,
  useGetFeaturedArticleQuery,
  useGetAlertBarEntryQuery,
} = dashboardApiSlice;

export default dashboardApiSlice;
