import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import toolbar from './config';
import StyledEditor from './styles';

const GenEditor = ({ editorState, handleEditorStateChange, editorProps }) => (
  <StyledEditor>
    <Editor
      toolbarClassName="toolbarClassName"
      wrapperClassName="demo-wrapper"
      editorClassName="demo-editor"
      editorState={editorState}
      onEditorStateChange={handleEditorStateChange}
      toolbar={toolbar}
      placeholder="Your thoughts..."
      spellCheck
      {...editorProps}
    />
  </StyledEditor>
);

export default GenEditor;
